@font-face {
    font-family: 'poppins';
    src:
        url('../static/fonts/poppins/poppins-regular.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/poppins/poppins-regular.ttf') format('ttf'),
        url('../static/fonts/poppins/poppins-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'poppins';
    src:
        url('../static/fonts/poppins/poppins-medium.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/poppins/poppins-medium.ttf') format('ttf'),
        url('../static/fonts/poppins/poppins-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'poppins';
    src:
        url('../static/fonts/poppins/poppins-semibold.eot?#iefix') format('embedded-opentype'),
        url('../static/fonts/poppins/poppins-semibold.ttf') format('ttf'),
        url('../static/fonts/poppins/poppins-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
}
